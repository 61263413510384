import AuthenticatedRoutesWrapper from '@customer-web-app/domains/authentication/components/authenticated-routes-wrapper';
import PublicRoutesWrapper from '@customer-web-app/domains/authentication/components/public-routes-wrapper';
import MainSideNavigation from '@customer-web-app/domains/shared/components/main-side-navigation';
import CoreProvider from '@customer-web-app/domains/shared/providers/core-provider';
import { AppProps } from 'next/app';
import Head from 'next/head';

import '@blastradius/ui/core';
import { fronteggThemeOptions } from '@customer-web-app/domains/authentication/mappers/frontegg-theme-options';
import Footer from '@customer-web-app/domains/shared/components/footer';
import Main from '@customer-web-app/domains/shared/components/main';
import MainSideNavigationMobile from '@customer-web-app/domains/shared/components/main-side-navigation-mobile';
import { ApplicationRoutes } from '@customer-web-app/domains/shared/enums/application-routes';
import '@customer-web-app/domains/shared/globals.scss';
import { useBreakpoint } from '@customer-web-app/domains/shared/hooks/use-media-breakpoint';
import BreadcrumbsProvider from '@customer-web-app/domains/shared/providers/breadcrumbs-provider';
import { withFronteggApp } from '@frontegg/nextjs/pages';
import Script from 'next/script';
import 'remixicon/fonts/remixicon.css';

function CustomerApp({ Component, pageProps, router }: AppProps) {
  const { isLg } = useBreakpoint('lg');
  return (
    <>
      <Head>
        <title>Radiant Security</title>
      </Head>
      <CoreProvider>
        <PublicRoutesWrapper>
          <Main>
            <Component {...pageProps} />
          </Main>
        </PublicRoutesWrapper>
        <AuthenticatedRoutesWrapper>
          {router.pathname !== ApplicationRoutes.Frontegg &&
            (!isLg ? <MainSideNavigationMobile /> : <MainSideNavigation />)}
          <Main>
            <BreadcrumbsProvider>
              <Component {...pageProps} />
              <Footer />
            </BreadcrumbsProvider>
          </Main>
        </AuthenticatedRoutesWrapper>
      </CoreProvider>
      {process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && (
        <Script
          id="analytics-provider"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="/api/analytics";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
          heap.load("${process.env.NEXT_PUBLIC_HEAP_ANALYTICS_ID}");
        `,
          }}
        />
      )}
    </>
  );
}

export default withFronteggApp(CustomerApp, {
  customLoader: true,
  themeOptions: {
    ...fronteggThemeOptions.dark,
    loginBox: {
      ...fronteggThemeOptions.dark.loginBox,
      login: {
        ...fronteggThemeOptions.dark.loginBox.login,
        docTitle: 'Login - Radiant Security',
      },
      signup: {
        ...fronteggThemeOptions.dark.loginBox.signup,
        docTitle: 'Sign Up - Radiant Security',
      },
      forgotPassword: {
        ...fronteggThemeOptions.dark.loginBox.forgotPassword,
        docTitle: 'Forgot your password? - Radiant Security',
      },
      resetPassword: {
        ...fronteggThemeOptions.dark.loginBox.resetPassword,
        docTitle: 'Define your password - Radiant Security',
      },
    },
  },
  authOptions: {
    keepSessionAlive: true,
  },
  contextOptions: {
    baseUrl: process.env.NEXT_PUBLIC_FRONTEGG_BASE_URL,
  },
});
